import {
  Box,
  BoxProps,
  styled,
  Tab,
  Typography,
  TypographyProps,
} from '@mui/material';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { INTER } from 'constants/fonts';

interface ILogoProps extends BoxProps {
  src: string;
}

export const StyledBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  '& .MuiTabs-indicator': {
    height: '0px',
  },
}));

export const CompanyName = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const GeneralText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '12.77px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const CompanyInfoTextContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0px',
  height: 'fit-content',
}));

export const CorporateInfoContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
}));

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  paddingTop: '30px',
  '& .MuiTabs-flexContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '15px',
    background: 'inherit',
  },
  '& .MuiTab-root': {
    gap: '7px',
    justifyContent: 'start',
    '& .MuiTab-iconWrapper': {
      margin: 0,
    },
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    paddingLeft: '16px',
    width: '100%',
    height: '40px',
    minHeight: '40px',
    borderRadius: '6px',
    textTransform: 'none',
    transition: '0.15s linear',
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    lineHeight: '20px',
    letterSpacing: '-0.352px',
  },
  '&.MuiButtonBase-root.MuiTab-root': {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
    fontFamily: INTER,
    lineHeight: '20px',
    letterSpacing: '-0.352px',
    '&.Mui-selected': {
      fontWeight: 400,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&:hover': {
      fontWeight: 400,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

export const LogoContainer = styled(Box)<BoxProps>(() => ({
  width: '42px',
  height: '42px',
  borderRadius: '50%',
}));

export const CompanyInfo = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  width: '100%',
  height: 'fit-content',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
}));
