import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setError, setLoading } from '../extraReducersHelpers';
import {
  IAccountInfo,
  IAdminInfo,
  IClient,
  IClientIntegration,
  IOrganization,
  IOrganizationByID,
  IUserOrganizations,
} from 'types/organizations.type';
import {
  retrieveAccountInfo,
  retrieveClientIntegrations,
  retrieveClientOrganization,
  retrieveMSPOrganization,
  updateOrganization,
} from './organizationOperations';
import { ILoadableState } from 'types/store.type';
import { IUpdateMSPContactBody } from 'types/api.type';

interface IOrganizationState extends ILoadableState {
  admin: IAdminInfo | null;
  userOrganizations: IUserOrganizations | null;
  mainOrganization: IOrganization | null;
  mspInformation: IOrganizationByID | null;
  clients: IClient[];
  selectedClient: IOrganizationByID | null;
  clientIntegrations: IClientIntegration[];
  teamMemberToUpdate: IUpdateMSPContactBody | null;
  deleteList: number[];
  deleteDataType: 'Clients' | 'Team Members' | null;
}

const initialState: IOrganizationState = {
  admin: null,
  userOrganizations: null,
  mainOrganization: null,
  mspInformation: null,
  clients: [],
  selectedClient: null,
  clientIntegrations: [],
  teamMemberToUpdate: null,
  deleteList: [],
  deleteDataType: null,
  loading: false,
  error: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization: () => initialState,
    resetSelectedClient(state) {
      state.selectedClient = initialState.selectedClient;
    },
    resetMSPInformation(state) {
      state.mspInformation = initialState.mspInformation;
    },
    setTeamMemberToUpdate(state, action: PayloadAction<IUpdateMSPContactBody>) {
      state.teamMemberToUpdate = action.payload;
    },
    resetTeamMemberToUpdate(state) {
      state.teamMemberToUpdate = initialState.teamMemberToUpdate;
    },
    setDeleteList(state, action: PayloadAction<number[]>) {
      state.deleteList = action.payload;
    },
    setDeleteDataType(
      state,
      action: PayloadAction<'Clients' | 'Team Members' | null>
    ) {
      state.deleteDataType = action.payload;
    },
    addElementToDeleteList(state, action: PayloadAction<number>) {
      state.deleteList.push(action.payload);
    },
    removeElementFromDeleteList(state, action: PayloadAction<number>) {
      state.deleteList = state.deleteList.filter(
        (element: number) => element !== action.payload
      );
    },
    resetDeleteData(state) {
      state.deleteList = initialState.deleteList;
      state.deleteDataType = initialState.deleteDataType;
    },
  },
  extraReducers: builder => {
    builder
      // retrieveAccountInfo
      .addCase(retrieveAccountInfo.pending, setLoading)
      .addCase(
        retrieveAccountInfo.fulfilled,
        (state, { payload }: PayloadAction<IAccountInfo>) => {
          state.loading = false;
          state.admin = payload.admin;
          state.mainOrganization = payload.home;
        }
      )
      .addCase(retrieveAccountInfo.rejected, setError)
      // retrieveClientOrganization
      .addCase(retrieveClientOrganization.pending, setLoading)
      .addCase(
        retrieveClientOrganization.fulfilled,
        (state, { payload }: PayloadAction<IOrganizationByID>) => {
          state.loading = false;
          state.selectedClient = payload;
        }
      )
      .addCase(retrieveClientOrganization.rejected, setError)
      // retrieveMSPOrganization
      .addCase(retrieveMSPOrganization.pending, setLoading)
      .addCase(
        retrieveMSPOrganization.fulfilled,
        (state, { payload }: PayloadAction<IOrganizationByID>) => {
          state.loading = false;
          state.mspInformation = payload;
        }
      )
      .addCase(retrieveMSPOrganization.rejected, setError)
      // updateOrganization
      .addCase(updateOrganization.pending, setLoading)
      .addCase(updateOrganization.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateOrganization.rejected, setError)
      // retrieveClientIntegrations
      .addCase(retrieveClientIntegrations.pending, setLoading)
      .addCase(retrieveClientIntegrations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clientIntegrations = payload.intgrns;
      })
      .addCase(retrieveClientIntegrations.rejected, setError);
  },
});

export const {
  resetOrganization,
  resetSelectedClient,
  resetMSPInformation,
  setTeamMemberToUpdate,
  resetTeamMemberToUpdate,
  setDeleteList,
  setDeleteDataType,
  addElementToDeleteList,
  removeElementFromDeleteList,
  resetDeleteData,
} = organizationSlice.actions;

export default organizationSlice.reducer;
