import { FlatButton } from 'ui';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeModal } from 'reduxStore/modal/modalSlice';
import {
  Buttons,
  IconAndTitle,
  Title,
  WrapperBusinessSettingsChangeConfirm,
} from './BusinessSettingsChangeConfirm.styled';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { resetMSPInformation } from 'reduxStore/organization/organizationSlice';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

const BusinessSettingsChangeConfirm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLeave = async () => {
    await dispatch(retrieveAccountInfo(null)).unwrap();
    dispatch(resetMSPInformation());
    navigate(ROUTES.DASHBOARD);
    dispatch(closeModal());
  };

  const handleStay = async () => {
    await dispatch(retrieveAccountInfo(null)).unwrap();
    dispatch(closeModal());
  };

  return (
    <WrapperBusinessSettingsChangeConfirm>
      <IconAndTitle>
        <InfoIcon
          sx={{
            fontSize: '50px',
            color: '#868686',
          }}
        />
        <Title>Your business information has been updated</Title>
      </IconAndTitle>

      <Buttons>
        <FlatButton
          onClick={handleStay}
          variant="outlined"
          width="223px"
          height="59px"
          borderradius="12px"
        >
          Stay on page
        </FlatButton>
        <FlatButton
          onClick={handleLeave}
          variant="contained"
          width="223px"
          height="59px"
          borderradius="12px"
        >
          Back to home
        </FlatButton>
      </Buttons>
    </WrapperBusinessSettingsChangeConfirm>
  );
};
export default BusinessSettingsChangeConfirm;
