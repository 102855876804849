import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/assignments';
import {
  ICreateAssignmentBody,
  ICreateClientContactBody,
  IUpdateAssignmentBody,
  IUpdateClientContactBody,
} from 'types/assignments.type';

const retrieveAssignments = createAsyncThunk(
  'assignments/retrieveAssignments',
  async (clientId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveAssignments(clientId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving assignments error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const removeAssignment = createAsyncThunk(
  'assignments/removeAssignment',
  async (assignmentId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRemoveAssignment(assignmentId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'removing assignment error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const updateAssignment = createAsyncThunk(
  'assignments/updateAssignment',
  async (body: IUpdateAssignmentBody, { rejectWithValue }) => {
    try {
      const result = await api.postUpdateAssignment(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'updating assignment error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createAssignment = createAsyncThunk(
  'assignments/createAssignment',
  async (body: ICreateAssignmentBody, { rejectWithValue }) => {
    try {
      const result = await api.postCreateAssignment(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'creating assignment error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveClientContacts = createAsyncThunk(
  'assignments/retrieveClientContacts',
  async (clientId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClientContacts(clientId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving client contacts error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createClientContact = createAsyncThunk(
  'assignments/createClientContact',
  async (body: ICreateClientContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postCreateClientContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'creating client contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const updateClientContact = createAsyncThunk(
  'assignments/updateClientContact',
  async (body: IUpdateClientContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postUpdateClientContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'updating client contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export {
  retrieveAssignments,
  removeAssignment,
  updateAssignment,
  createAssignment,
  retrieveClientContacts,
  createClientContact,
  updateClientContact,
};
