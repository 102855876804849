import { BYTES_IN_2MB } from 'constants/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import {
  getAssignmentsError,
  getClientContactToUpdate,
} from 'reduxStore/assignments/assignmentsSelectors';
import { getSelectedClient } from 'reduxStore/organization/organizationSelectors';
import {
  IClientContact,
  ICreateClientContactBody,
  IUpdateClientContactBody,
} from 'types/assignments.type';
import { getBase64 } from 'utils';
import * as Yup from 'yup';
import {
  Title,
  WrapperAddOrUpdateClientContact,
  Error,
  Header,
} from './AddOrUpdateClientContactModal.styled';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { BlockedInput, FlatButton, Input, SectionUploadSmall } from 'ui';
import { FormControl, Grid } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  createClientContact,
  updateClientContact,
} from 'reduxStore/assignments/assignmentsOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { resetClientContactToUpdate } from 'reduxStore/assignments/assignmentsSlice';

interface IAddOrUpdateClientContactModalProps {
  contactFullName?: string;
}

const validationSchema = Yup.object({
  first_name: Yup.string()
    .min(2, 'Minimum 2 symbols are required')
    .required('First name is required'),
  last_name: Yup.string()
    .min(2, 'Minimum 2 symbols are required')
    .required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const AddOrUpdateClientContactModal = ({
  contactFullName,
}: IAddOrUpdateClientContactModalProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetClientContactToUpdate());
    };
  }, [dispatch]);

  const clientInfo = useAppSelector(getSelectedClient);
  const clientContactToUpdate = useAppSelector(getClientContactToUpdate);
  const error = useAppSelector(getAssignmentsError);

  const [isUpdate] = useState(Boolean(clientContactToUpdate?.email));
  const [base64Image, setBase64Image] = useState(
    clientContactToUpdate?.icon || ''
  );
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  const handleSubmit = async (value: IClientContact) => {
    const createClientContactBody: ICreateClientContactBody = {
      client_id: clientInfo?.id ?? 0,
      email: value.email,
      first_name: value.first_name ?? '',
      last_name: value.last_name ?? '',
      phone: value.phone === '+' ? '' : value.phone, //TODO: need to find better way
      icon: base64Image,
    };

    const updateClientContactBody: IUpdateClientContactBody = {
      id: value.id,
      first_name:
        value.first_name === clientContactToUpdate?.first_name
          ? null
          : value.first_name,
      last_name:
        value.last_name === clientContactToUpdate?.last_name
          ? null
          : value.last_name,
      phone:
        value.phone === clientContactToUpdate?.phone
          ? null
          : value.phone === '+' //TODO: need to find better way
            ? ''
            : value.phone,
      icon: base64Image === clientContactToUpdate?.icon ? null : base64Image,
    };
    try {
      if (isUpdate) {
        await dispatch(updateClientContact(updateClientContactBody)).unwrap();
        dispatch(
          setModalProps({
            radius: '18px',
            title: value.email,
            additionalText: 'update',
          })
        );
      } else {
        await dispatch(createClientContact(createClientContactBody)).unwrap();
        dispatch(setModalProps({ radius: '18px', title: value.email }));
      }
      dispatch(openModal());
      dispatch(setContent('AddOrUpdateTeamMemberConfirm'));
    } catch (error) {
      return;
    }
  };

  const initialValues: IClientContact = {
    id: clientContactToUpdate?.id ?? 0,
    email: clientContactToUpdate?.email ?? '',
    first_name: clientContactToUpdate?.first_name ?? '',
    last_name: clientContactToUpdate?.last_name ?? '',
    phone: clientContactToUpdate?.phone ?? null,
    icon: base64Image,
  };

  return (
    <WrapperAddOrUpdateClientContact>
      <Header>
        <Title>
          {isUpdate
            ? `Update ${contactFullName} Contact`
            : `Create new Client Contact for ${clientInfo?.name}`}
        </Title>
        {!!error && <Error>{error}</Error>}
      </Header>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  component={Input}
                  name="first_name"
                  label="First Name"
                  placeholder="Enter First Name"
                  helperText={<ErrorMessage name="first_name" />}
                />
              </Grid>
              <Grid item xs={8}>
                <Field
                  component={Input}
                  name="last_name"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  helperText={<ErrorMessage name="last_name" />}
                />
              </Grid>
              <Grid item xs={8}>
                {isUpdate ? (
                  <Field
                    component={BlockedInput}
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    helperText={<ErrorMessage name="email" />}
                    isBlocked="true"
                    blockedText="Oops! You can't change email for now."
                  />
                ) : (
                  <Field
                    component={Input}
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    helperText={<ErrorMessage name="email" />}
                  />
                )}
              </Grid>

              <Grid item xs={8}>
                <FormControl fullWidth>
                  <MuiPhoneNumber
                    defaultCountry={'us'}
                    name="phone"
                    value={values.phone}
                    label="Phone Number"
                    variant="outlined"
                    placeholder="Enter Phone Number"
                    onChange={value => setFieldValue('phone', value)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        height: '59px',
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <SectionUploadSmall
                  handleUpload={handleUpload}
                  onDelete={handleDeleteImage}
                  imageUrl={base64Image}
                  error={imageError}
                  title="Upload Icon"
                />
              </Grid>

              <Grid item xs={8} marginTop="50px">
                <FlatButton
                  width="250px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  {isUpdate ? (
                    <>Update Client Contact</>
                  ) : (
                    <>Create Client Contact</>
                  )}
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperAddOrUpdateClientContact>
  );
};

export default AddOrUpdateClientContactModal;
