import { MainNavigationMenu } from 'ui';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganization } from 'reduxStore/organization/organizationSelectors';
import { ITabMenuProps } from 'types';
import { retrieveMSPOrganization } from 'reduxStore/organization/organizationOperations';
import { useNavigate } from 'react-router';

const NavigationLogically = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mainOrg = useAppSelector(getMainOrganization);

  const navigationOptions: ITabMenuProps[] = [
    {
      title: 'Overview',
      url: ROUTES.DASHBOARD,
      icon: <DashboardCustomizeOutlinedIcon />,
      onClick: () => {
        navigate(ROUTES.DASHBOARD);
      },
    },
    ...(mainOrg?.role.name === 'Global Admin'
      ? [
          {
            title: 'Business Settings',
            url: ROUTES.BUSINESS_SETTINGS,
            icon: <SettingsOutlinedIcon />,
            onClick: async () => {
              await dispatch(retrieveMSPOrganization(mainOrg.id)).unwrap();
              navigate(ROUTES.BUSINESS_SETTINGS);
            },
          },
        ]
      : []),
    {
      title: 'Services',
      url: ROUTES.SERVICES,
      icon: <HomeRepairServiceOutlinedIcon />,
      onClick: () => {
        navigate(ROUTES.SERVICES);
      },
    },
    {
      title: 'Clients',
      url: ROUTES.CLIENTS,
      icon: <PersonOutlineOutlinedIcon key="users" />,
      onClick: () => {
        navigate(ROUTES.CLIENTS);
      },
    },
    {
      title: 'Team',
      url: ROUTES.TEAM_MEMBERS,
      icon: <GroupOutlinedIcon />,
      onClick: () => {
        navigate(ROUTES.TEAM_MEMBERS);
      },
    },
    {
      title: 'Ticketing',
      url: ROUTES.TICKETS,
      icon: <LocalActivityOutlinedIcon />,
      onClick: () => {
        navigate(ROUTES.TICKETS);
      },
    },
    {
      title: 'Audit Logs',
      url: ROUTES.AUDIT_LOGS,
      icon: <TerminalOutlinedIcon />,
      onClick: () => {
        navigate(ROUTES.AUDIT_LOGS);
      },
    },
  ];

  return (
    <MainNavigationMenu
      options={navigationOptions}
      orgName={mainOrg?.name}
      orgIcon={mainOrg?.icon}
    />
  );
};

export default NavigationLogically;
