import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperAddClient = styled(Box)<BoxProps>(() => ({
  background: COLORS.white,
  padding: '30px',
  borderRadius: '12px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '48px',
  letterSpacing: '-0.701px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  paddingLeft: '30px',
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '36px',
  letterSpacing: '-0.308px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  letterSpacing: '-0.308px',
}));

export const MenuItemBox = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  marginTop: '10px',
  letterSpacing: '-0.352px',
}));
