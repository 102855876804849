import {
  Delete,
  Title,
  Update,
  WrapperActions,
  WrapperClientContact,
} from './ClientContact.styled';

interface IClientContactProps {
  title: string;
  onUpdate: () => void;
  onDelete?: () => void;
}

const ClientContact = ({ title, onDelete, onUpdate }: IClientContactProps) => {
  return (
    <WrapperClientContact>
      <Title>{title}</Title>
      <WrapperActions>
        <Update underline="none" onClick={onUpdate}>
          Update
        </Update>
        <Delete underline="none" onClick={onDelete}>
          Delete
        </Delete>
      </WrapperActions>
    </WrapperClientContact>
  );
};

export default ClientContact;
