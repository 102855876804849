import { useState } from 'react';
import { FormControl, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SectionTitle, SectionUploadLarge } from 'ui';
import { Input } from 'ui/inputs';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainer } from 'views';
import { Error } from './OrgProfile.styled';
import { useNavigate } from 'react-router';
import createValidationSchema from './validationSchema';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import { IOrgProfileFormValues } from 'types';
import { createMSP } from 'reduxStore/createProfile/createProfileOperations';
import { getCreateProfileError } from 'reduxStore/createProfile/createProfileSelectors';
import { IOrganizationProfileRequestBody } from 'types/api.type';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';
import MuiPhoneNumber from 'material-ui-phone-number';

export type ButtonsOrgTypeStatus = 'active' | 'inactive';

const OrgProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const error = useAppSelector(getCreateProfileError);

  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const [base64Image, setBase64Image] = useState('');
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };
  const initialValues: IOrgProfileFormValues = {
    name: '',
    admins: [],
    street: '',
    city: '',
    state: '',
    zipCode: '',
    phone: null,
  };

  const handleSubmit = async (values: IOrgProfileFormValues) => {
    const body: IOrganizationProfileRequestBody = {
      name: values.name,
      admins: [],
      address: {
        street: values.street,
        city: values.city,
        state: values.state,
        zip_code: values.zipCode,
      },
      icon: base64Image,
      phone: values.phone,
    };

    try {
      await dispatch(createMSP(body)).unwrap();
      await dispatch(retrieveAccountInfo(null)).unwrap();

      navigate(ROUTES.TEAM_MEMBERS_CONFIG);
    } catch (error) {
      return;
    }
  };

  return (
    <Box component="section" position="relative">
      <SectionTitle>Organization Profile</SectionTitle>
      {error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box display="flex" flexDirection="row" gap="15vw" marginTop="46px">
              <Box>
                <Grid container spacing="38px" width="100%" maxWidth="630px">
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="name"
                      label="Company Name"
                      helperText={<ErrorMessage name="name" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="street"
                      label="Address"
                      helperText={<ErrorMessage name="street" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="city"
                      label="City"
                      helperText={<ErrorMessage name="city" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="state"
                      label="State"
                      helperText={<ErrorMessage name="state" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="zipCode"
                      label="Zip code"
                      helperText={<ErrorMessage name="zipCode" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <MuiPhoneNumber
                        defaultCountry={'us'}
                        name="phone"
                        value={values.phone}
                        label="Phone Number"
                        variant="outlined"
                        placeholder="Enter Phone Number"
                        onChange={value => setFieldValue('phone', value)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            height: '59px',
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <SectionUploadLarge
                handleUpload={handleUpload}
                onDelete={handleDeleteImage}
                imageUrl={base64Image}
                error={imageError}
                title="Company Logo"
              />
            </Box>
            <Box marginTop="47px">
              <ButtonsContainer
                titleButton="Next"
                type="submit"
                onClickBack={handleLogout}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default OrgProfile;
