import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';
import {
  getIsOpen,
  getContent,
  getModalProps,
} from 'reduxStore/modal/modalSelectors';
import { StyledDialog, StyledWrapperDialog, StyledLine } from './Modal.styled';
import {
  AddIntegrationConfirm,
  AddOrUpdateTeamMemberConfirm,
  DeleteConfirmation,
  AddClientModal,
  AddTeamMemberModal,
  AddIntegrationModal,
  DeleteIntegrationModal,
  BusinessSettingsChangeConfirm,
  AccountSettingsChangeConfirm,
  MassDeletionConfirm,
  AddOrUpdateClientConfirm,
  CreateAssignment,
  AddOrUpdateClientContactModal,
} from './components';
import { ITableColumn, ITableRow } from 'types';

//TODO: possibly needs refactoring because not everything is needed
export interface IModalProps {
  status?: 'error' | 'success';
  isAdmin?: boolean;
  position?: 'top' | 'right';
  top?: string;
  radius?: string;
  isNextClick?: boolean;
  background?: string;
  zIndex?: number;
  title?: string;
  additionalText?: string;
  icon?: 'edit' | 'remove' | 'check' | 'switch' | 'view';
  columns?: ITableColumn[];
  tableData?: ITableRow[];
  anyIsStatement?: 'true';
  progress?: number;
  apiValue?: any;
}

const Modal = () => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(getIsOpen);
  const content = useAppSelector(getContent);
  const modalProps = useAppSelector(getModalProps);

  const modalContent = () => {
    switch (content) {
      case 'AddOrUpdateClientConfirm':
        return (
          <AddOrUpdateClientConfirm
            nameOfClient={modalProps?.title}
            type={modalProps?.additionalText}
          />
        );
      case 'AddOrUpdateTeamMemberConfirm':
        return (
          <AddOrUpdateTeamMemberConfirm
            emailOfContact={modalProps?.title}
            type={modalProps?.additionalText}
          />
        );
      case 'AddIntegrationConfirm':
        return (
          <AddIntegrationConfirm
            nameOfClient={modalProps?.title}
            nameOfService={modalProps?.additionalText}
          />
        );
      case 'DeleteConfirmation':
        return (
          <DeleteConfirmation
            apiValue={modalProps?.apiValue}
            deletionType={modalProps?.title}
            doesNotNeedNavigation={modalProps?.anyIsStatement}
          />
        );
      case 'AddClientModal':
        return <AddClientModal />;
      case 'AddTeamMemberModal':
        return <AddTeamMemberModal />;
      case 'AddIntegrationModal':
        return <AddIntegrationModal />;
      case 'DeleteIntegrationModal':
        return <DeleteIntegrationModal />;
      case 'BusinessSettingsChangeConfirm':
        return <BusinessSettingsChangeConfirm />;
      case 'AccountSettingsChangeConfirm':
        return <AccountSettingsChangeConfirm />;
      case 'MassDeletionConfirm':
        return <MassDeletionConfirm title={modalProps?.title} />;
      case 'CreateAssignment':
        return <CreateAssignment />;
      case 'AddOrUpdateClientContactModal':
        return (
          <AddOrUpdateClientContactModal contactFullName={modalProps?.title} />
        );
      default:
        return null;
    }
  };

  const handleCloseModal = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (content === 'MassDeletionConfirm' && reason === 'backdropClick') return;
    dispatch(closeModal());
  };

  return (
    <>
      {isOpen && (
        <StyledWrapperDialog>
          <StyledDialog
            open={isOpen}
            onClose={handleCloseModal}
            status={modalProps?.status}
            isadmin={modalProps?.isAdmin}
            position={modalProps?.position}
            top={modalProps?.top}
            radius={modalProps?.radius}
            background={modalProps?.background}
            zindex={modalProps?.zIndex}
          >
            <StyledLine status={modalProps?.status} />
            {modalContent()}
          </StyledDialog>
        </StyledWrapperDialog>
      )}
    </>
  );
};

export default Modal;
