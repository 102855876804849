import {
  Box,
  BoxProps,
  Link,
  LinkProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IBoxProps extends BoxProps {
  width?: string;
  height?: string;
}

export const WrapperAssignmentConfig = styled(Box)<IBoxProps>(
  ({ height, width, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height,
    width,
    padding: '20px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.light}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
);

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.347px',
}));

export const Unassign = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: INTER,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.347px',
  cursor: 'pointer',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const MenuItemBox = {
  display: 'flex',
  justifyContent: 'center',
};
