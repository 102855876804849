import { useState, SyntheticEvent, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  StyledTabs,
  //StyledLink,
  StyledBox,
  CompanyName,
  LogoBox,
  LogoContainer,
  CompanyInfo,
  CompanyInfoTextContainer,
  GeneralText,
  CorporateInfoContainer,
  StyledTab,
} from './MainNavigationMenu.styled';
import { ITabMenuProps } from 'types';
import { initiateTabValueForNavTabs } from 'utils';
import { Logo } from 'ui';
import { Box } from '@mui/material';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

interface INavTabsProps {
  options: ITabMenuProps[];
  orgName: string;
  orgIcon?: string;
}

const MainNavigationMenu = ({ options, orgName, orgIcon }: INavTabsProps) => {
  const { pathname } = useLocation();

  const initiateValue = () =>
    initiateTabValueForNavTabs(options, pathname, true);

  const [value, setValue] = useState(initiateValue);

  useEffect(() => {
    if (pathname !== '/') {
      const parentPath = pathname.split('/').filter(String)[0];
      const parentIndex = options.findIndex(i => i.url === `/${parentPath}`);
      setValue(parentIndex !== -1 ? parentIndex : 0);
    }
    if (pathname === '/') {
      setValue(0);
    }
  }, [pathname, options]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      <Box gap="5px">
        <CompanyInfo>
          <LogoContainer>
            {orgIcon ? (
              <LogoBox src={orgIcon} />
            ) : (
              <BrokenImageOutlinedIcon sx={{ fontSize: '42px' }} />
            )}
          </LogoContainer>
          <CompanyInfoTextContainer>
            <CompanyName>{orgName}</CompanyName>
            <CorporateInfoContainer>
              <GeneralText>Powered by</GeneralText>
              <Logo width="60px" height="20px" />
            </CorporateInfoContainer>
          </CompanyInfoTextContainer>
        </CompanyInfo>

        <StyledTabs value={value} onChange={handleChange}>
          {options.map((option, index) => (
            <StyledTab
              key={`navigation-tab-${index}`}
              iconPosition="start"
              icon={option.icon}
              label={option.title}
              onClick={option.onClick}
            />
          ))}
        </StyledTabs>
      </Box>
    </StyledBox>
  );
};

export default MainNavigationMenu;
