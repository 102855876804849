import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import {
  retrieveAssignableContacts,
  retrieveAssignableRoles,
  retrieveClientsRef,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getAssignableContacts,
  getAssignableRoles,
  getClientsRef,
} from 'reduxStore/createProfile/createProfileSelectors';
import { getSelectedClient } from 'reduxStore/organization/organizationSelectors';
import { ICreateAssignmentBody } from 'types/assignments.type';
import * as Yup from 'yup';
import {
  Title,
  WrapperCreateAssignment,
  Error,
  MenuItemBox,
  MenuItemText,
} from './CreateAssignment.styled';
import { getAssignmentsError } from 'reduxStore/assignments/assignmentsSelectors';
import { Field, Form, Formik } from 'formik';
import { createAssignment } from 'reduxStore/assignments/assignmentsOperations';
import { Grid, MenuItem, TextField } from '@mui/material';
import { FlatButton } from 'ui';
import { closeModal } from 'reduxStore/modal/modalSlice';

const validationSchema = Yup.object({
  client_id: Yup.string()
    .required('Client is required')
    .test('not-select', 'Client is required', value => value !== '0'),
  contact_id: Yup.string()
    .required('Team member is required')
    .test('not-select', 'Team member is required', value => value !== '0'),
  role_id: Yup.string()
    .required('Role is required')
    .test('not-select', 'Role is required', value => value !== '0'),
});

const ReformatContact = (arr: (string | undefined)[]) => {
  const updatedContact = arr.map(value => (value === null ? 'Missing' : value));

  return updatedContact
    .map((value, index) =>
      index >= 0 && index < 2 ? ` ${value}` : ` - ${value}`
    )
    .join(' ');
};

const CreateAssignment = () => {
  const dispatch = useAppDispatch();

  const clientsRefs = useAppSelector(getClientsRef);
  const assignableRoles = useAppSelector(getAssignableRoles);
  const teamMembers = useAppSelector(getAssignableContacts);
  const clientInfo = useAppSelector(getSelectedClient);
  const error = useAppSelector(getAssignmentsError);

  useEffect(() => {
    dispatch(retrieveAssignableRoles(null));
    dispatch(retrieveAssignableContacts(null));
    dispatch(retrieveClientsRef(null));
  }, [dispatch]);

  const initialValues: ICreateAssignmentBody = {
    client_id: clientInfo?.id || 0,
    contact_id: 0,
    role_id: 0,
  };

  const handleSubmit = async (value: ICreateAssignmentBody) => {
    try {
      await dispatch(createAssignment(value)).unwrap();
      dispatch(closeModal());
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperCreateAssignment>
      <Title>Create Integration</Title>
      {!!error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container flexDirection="column" spacing={2}>
              <Grid item xs={8}>
                <Field
                  name="client_id"
                  as={TextField}
                  label="Select Client"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.client_id && Boolean(errors.client_id)}
                  helperText={touched.client_id && errors.client_id}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '59px',
                    },
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>No Client</MenuItemText>
                  </MenuItem>
                  {clientsRefs.map((client, index) => (
                    <MenuItem key={index} value={client.id} style={MenuItemBox}>
                      <MenuItemText>{client.name}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="contact_id"
                  as={TextField}
                  label="Select Team Member"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.contact_id && Boolean(errors.contact_id)}
                  helperText={touched.contact_id && errors.contact_id}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '59px',
                    },
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>No Team Member</MenuItemText>
                  </MenuItem>
                  {teamMembers.map((teamMember, index) => (
                    <MenuItem
                      key={index}
                      value={teamMember.id}
                      style={MenuItemBox}
                    >
                      <MenuItemText>
                        {ReformatContact([
                          teamMember.first_name,
                          teamMember.last_name,
                          teamMember.email,
                          teamMember.role.name,
                        ])}
                      </MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="role_id"
                  as={TextField}
                  label="Select Role"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.role_id && Boolean(errors.role_id)}
                  helperText={touched.role_id && errors.role_id}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '59px',
                    },
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>No Role</MenuItemText>
                  </MenuItem>
                  {assignableRoles.map((role, index) => (
                    <MenuItem key={index} value={role.id} style={MenuItemBox}>
                      <MenuItemText>{role.name}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={8} marginTop="15px">
                <FlatButton
                  width="250px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Create
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperCreateAssignment>
  );
};

export default CreateAssignment;
