import { IUpdateOrgBody } from 'types/api.type';
import instance from './api';

export const postGetAccountInfo = async (def: null) => {
  const { data } = await instance.post('v1/account', def);

  return data;
};

export const postRetrieveOrganization = async (orgId: number) => {
  const { data } = await instance.post('v1/organization', { id: orgId });

  return data;
};

export const postUpdateOrganization = async (body: IUpdateOrgBody) => {
  const { data } = await instance.post('v1/update-org', body);

  return data;
};

export const postRemoveListOfClients = async (ids: number[]) => {
  const { data } = await instance.post('v1/remove-clients', { ids });

  return data;
};

export const postRetrieveClientIntegrations = async (clientId: number) => {
  const { data } = await instance.post('v1/retrieve-client-intgrns', {
    client_id: clientId,
  });

  return data;
};
