import { useAppDispatch, useAppSelector } from 'hooks';
import * as Yup from 'yup';
import {
  retrieveClients,
  retrieveMSPContacts,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClients,
  getMSPContacts,
  getCreateProfileError,
} from 'reduxStore/createProfile/createProfileSelectors';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  MenuItemBox,
  MenuItemText,
  Title,
  WrapperDeleteContact,
  Error,
} from './DeleteTeamMember.styled';
import { Formik, Form, Field } from 'formik';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { FlatButton } from 'ui';
import { IDeleteContactBody } from 'types/api.type';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

interface IDeleteContactForm {
  id: number;
  client_id: number;
  is_total: number;
}

const validationSchema = Yup.object({
  id: Yup.string()
    .required('Team Member is required')
    .test('not-select', 'Team Member is required', value => value !== '0'),
});

const DeleteContact = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(getCreateProfileError);
  const contacts = useAppSelector(getMSPContacts);
  const clients = useAppSelector(getClients);
  const organizationId = useAppSelector(getMainOrganizationID);

  const ReformatContact = (arr: (string | undefined)[]) => {
    const updatedContact = arr.map(value =>
      value === null ? 'Missing' : value
    );

    return updatedContact
      .map((value, index) =>
        index >= 0 && index < 2 ? ` ${value}` : ` - ${value}`
      )
      .join(' ');
  };

  const handleSubmit = async (value: IDeleteContactForm) => {
    const body: IDeleteContactBody = {
      id: value.id,
      org_id: value.client_id !== 0 ? value.client_id : organizationId,
      is_total: value.is_total === 0 ? false : true,
    };
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'team member',
        apiValue: body,
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  useEffect(() => {
    dispatch(retrieveMSPContacts(organizationId));
    dispatch(retrieveClients(null));
  }, [dispatch, organizationId]);

  const initialValues: IDeleteContactForm = {
    id: 0,
    client_id: 0,
    is_total: 0,
  };

  return (
    <WrapperDeleteContact>
      <Title>Delete Team Member</Title>
      {!!error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  name="id"
                  as={TextField}
                  label="Select Team Member"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.id && Boolean(errors.id)}
                  helperText={touched.id && errors.id}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Team Member To Delete</MenuItemText>
                  </MenuItem>
                  {contacts?.map(contact => (
                    <MenuItem
                      key={contact.id}
                      value={contact.id}
                      style={MenuItemBox}
                    >
                      <MenuItemText>
                        {ReformatContact([
                          contact.first_name,
                          contact.last_name,
                          contact.email,
                          contact.role?.name,
                        ])}
                      </MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="client_id"
                  as={TextField}
                  label="Select Client To remove Team Member From them"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value={0} style={MenuItemBox}>
                    <MenuItemText>No Client</MenuItemText>
                  </MenuItem>
                  {Array.isArray(clients) &&
                    clients.map(client => (
                      <MenuItem
                        key={client.id}
                        value={client.id}
                        style={MenuItemBox}
                      >
                        <MenuItemText>{client.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="is_total"
                  as={TextField}
                  id="is_total"
                  label="Total Member Delete?"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'is_total', shrink: true }}
                >
                  <MenuItem value={0} style={MenuItemBox}>
                    <MenuItemText>No</MenuItemText>
                  </MenuItem>
                  <MenuItem value={1} style={MenuItemBox}>
                    <MenuItemText>Yes</MenuItemText>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={8}>
                <FlatButton width="200px" type="submit" variant="contained">
                  Delete Team Member
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperDeleteContact>
  );
};

export default DeleteContact;
