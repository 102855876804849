import { useRef } from 'react';
import {
  UploadImageWrapper,
  Circle,
  Caption,
  MainWrapper,
  ErrorMessage,
  DeleteIconButton,
} from './SectionUploadLarge.styled';
import { ButtonUpload, InfoText } from 'ui';
import { InputInvisible } from 'ui/inputs';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box } from '@mui/material';

interface ISectionUploadProps {
  handleUpload: (image: File, error?: string | null) => Promise<void>;
  onDelete?: () => void;
  imageUrl: string;
  error?: string | null;
  notAvailableToEdit?: 'true';
  title: string;
}

const SectionUploadLarge = ({
  handleUpload,
  onDelete,
  imageUrl,
  error,
  notAvailableToEdit,
  title,
}: ISectionUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (inputRef.current && !notAvailableToEdit) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onDelete?.();
  };

  return (
    <MainWrapper>
      <UploadImageWrapper>
        <Box
          sx={{
            position: 'relative',
            '&:hover #delete-icon': {
              opacity: 0.7,
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              borderRadius: '50%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.4)', // Dim color overlay
              transition: 'opacity 0.3s ease',
              pointerEvents: 'none', // Make sure the overlay doesn't block interactions
              opacity: 0,
            },

            '&:hover::before': {
              opacity: !notAvailableToEdit && imageUrl ? 1 : 0, // Show the dim effect on hover
            },
            width: '142px',
            height: '142px',
          }}
        >
          <Circle id="image-wrapper" sx={{ width: '142px', height: '142px' }}>
            {imageUrl ? (
              <img
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
                src={imageUrl}
                alt="Company profile"
              />
            ) : (
              <PhotoCameraIcon color="secondary" fontSize="large" />
            )}
            {!notAvailableToEdit && imageUrl && (
              <DeleteIconButton
                id="delete-icon"
                disableFocusRipple={false}
                onClick={handleDeleteImage}
              >
                Remove
              </DeleteIconButton>
            )}
          </Circle>
        </Box>

        <Caption>{title}</Caption>
        <InputInvisible
          ref={inputRef}
          accept="image/jpeg, image/png, image/gif"
          onUpload={handleUpload}
        />
        <ButtonUpload onClick={handleClickUpload}>Upload new</ButtonUpload>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </UploadImageWrapper>
      <InfoText text="(Accepted formats: PNG, JPG, GIF. Maximum size: 2MB)" />
    </MainWrapper>
  );
};

export default SectionUploadLarge;
