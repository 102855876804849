import { RootState } from 'reduxStore/store';

const getAssignments = (state: RootState) => state.assignments.assignments;
const getClientContacts = (state: RootState) =>
  state.assignments.clientContacts;
const getClientContactToUpdate = (state: RootState) =>
  state.assignments.clientContactToUpdate;
const getAssignmentsError = (state: RootState) => state.assignments.error;

export {
  getAssignments,
  getClientContacts,
  getClientContactToUpdate,
  getAssignmentsError,
};
