import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/createProfile';
import {
  IImportUsersBody,
  ISetUpSelectedCloudsBody,
  ICreateMSPContactBody,
  ICreateClientBody,
  IReceiveToolOrgsBody,
  ICreateIntegrationBody,
  IDeleteIntgrnBody,
  IDeleteContactBody,
  IOrganizationProfileRequestBody,
  IUpdateMSPContactBody,
} from 'types/api.type';

//TODO: Revise all functions and delete unused
const createMSP = createAsyncThunk(
  'createProfile/createMSP',
  async (body: IOrganizationProfileRequestBody, { rejectWithValue }) => {
    try {
      const id = await api.postCreateMSP(body);
      return id;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Creating MSP organization error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createClient = createAsyncThunk(
  'createProfile/createClient',
  async (body: ICreateClientBody, { rejectWithValue }) => {
    try {
      const id = await api.postCreateClient(body);
      return id;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Creating client error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const importUsersList = createAsyncThunk(
  'createProfile/importUsers',
  async (body: IImportUsersBody, { rejectWithValue }) => {
    try {
      const result = await api.postImportUsers(body);
      return result;
    } catch (error: any) {
      console.error('Error:', error.message);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClouds = createAsyncThunk(
  'createProfile/retrieveClouds',
  async (orgId: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postGetClouds(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clouds error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const setUpClouds = createAsyncThunk(
  'createProfile/setUpClouds',
  async (body: ISetUpSelectedCloudsBody, { rejectWithValue }) => {
    try {
      const result = await api.postSetUpClouds(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'setting up clouds error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const retrieveMSPRoles = createAsyncThunk(
  'createProfile/retrieveMSPRoles',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveMSPRoles(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving MSP roles error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveAssignableRoles = createAsyncThunk(
  'createProfile/retrieveAssignableRoles',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveAssignableRoles(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving assignable roles error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createMSPContact = createAsyncThunk(
  'createProfile/createMSPContact',
  async (body: ICreateMSPContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postCreateMSPContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'creating MSP contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const updateMSPContact = createAsyncThunk(
  'createProfile/updateMSPContact',
  async (body: IUpdateMSPContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postUpdateMSPContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'updating MSP contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveMSPContacts = createAsyncThunk(
  'createProfile/retrieveMSPContacts',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveMSPContacts(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving MSP contacts error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveAssignableContacts = createAsyncThunk(
  'createProfile/retrieveAssignableContacts',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveAssignableContacts(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving assignable contacts error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveClients = createAsyncThunk(
  'createProfile/retrieveClients',
  async (tool_id: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClients(tool_id);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clients error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveClientsRef = createAsyncThunk(
  'createProfile/retrieveClientsRef',
  async (tool_id: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClientsRef(tool_id);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clients as references error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveTools = createAsyncThunk(
  'createProfile/retrieveTools',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveTools(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving tools error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveToolsRef = createAsyncThunk(
  'createProfile/retrieveToolsRef',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveToolsRef(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving tools as references error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const checkAPICredentials = createAsyncThunk(
  'createProfile/checkAPICredentials',
  async (body: IReceiveToolOrgsBody, { rejectWithValue }) => {
    try {
      const result = await api.postCheckAPICredentials(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'checking API credentials error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createIntegration = createAsyncThunk(
  'createProfile/createIntegration',
  async (body: ICreateIntegrationBody, { rejectWithValue }) => {
    try {
      const result = await api.postCreateIntegration(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'creating integration error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveToolOrgs = createAsyncThunk(
  'createProfile/retrieveToolOrgs',
  async (toolId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveToolOrgs(toolId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving tool organizations error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteIntegration = createAsyncThunk(
  'createProfile/deleteIntegration',
  async (body: IDeleteIntgrnBody, { rejectWithValue }) => {
    try {
      const result = await api.postDeleteIntegration(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'deleting integration error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteClient = createAsyncThunk(
  'createProfile/deleteClient',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postDeleteClient(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'deleting client error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteContact = createAsyncThunk(
  'createProfile/deleteContact',
  async (body: IDeleteContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postDeleteContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'deleting contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export {
  createMSP,
  createClient,
  importUsersList,
  retrieveClouds,
  setUpClouds,
  retrieveMSPRoles,
  retrieveAssignableRoles,
  createMSPContact,
  updateMSPContact,
  retrieveMSPContacts,
  retrieveAssignableContacts,
  retrieveClients,
  retrieveClientsRef,
  retrieveTools,
  retrieveToolsRef,
  checkAPICredentials,
  createIntegration,
  retrieveToolOrgs,
  deleteIntegration,
  deleteClient,
  deleteContact,
};
