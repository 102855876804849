import {
  Box,
  BoxProps,
  Link,
  LinkProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperClientContact = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '59px',
  width: '100%',
  padding: '20px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.secondary.light}`,
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const WrapperActions = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  alignItems: 'center',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.347px',
}));

export const Delete = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: INTER,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.347px',
  cursor: 'pointer',
}));

export const Update = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: INTER,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.347px',
  cursor: 'pointer',
}));
