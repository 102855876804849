import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/organization';
import { IUpdateOrgBody } from 'types/api.type';

const retrieveAccountInfo = createAsyncThunk(
  'organization/getAccountInfo',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postGetAccountInfo(def);
      return result;
    } catch (error: any) {
      console.error('Get account information error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveMSPOrganization = createAsyncThunk(
  'organization/retrieveMSPOrganization',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveOrganization(orgId); // in the future will have different API calls then retrieveClientOrganization
      return result;
    } catch (error: any) {
      console.error('Getting MSP organization error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClientOrganization = createAsyncThunk(
  'organization/retrieveClientOrganization',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveOrganization(orgId); // in the future will have different API calls then retrieveMSPOrganization
      return result;
    } catch (error: any) {
      console.error('Getting client organization error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const updateOrganization = createAsyncThunk(
  'organization/updateOrganization',
  async (body: IUpdateOrgBody, { rejectWithValue }) => {
    try {
      const result = await api.postUpdateOrganization(body);
      return result;
    } catch (error: any) {
      console.error('Update organization error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const removeListOfClients = createAsyncThunk(
  'organization/removeListOfClients',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const result = await api.postRemoveListOfClients(ids);
      return result;
    } catch (error: any) {
      console.error('Remove list of clients error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClientIntegrations = createAsyncThunk(
  'organization/retrieveClientIntegrations',
  async (clientId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClientIntegrations(clientId);
      return result;
    } catch (error: any) {
      console.error('Get client integrations error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

export {
  retrieveAccountInfo,
  retrieveMSPOrganization,
  retrieveClientOrganization,
  updateOrganization,
  removeListOfClients,
  retrieveClientIntegrations,
};
