import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { signIn } from 'reduxStore/auth/authOperations';
import { clear0AuthState } from 'utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ROUTES } from 'routes/routes.const';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { userGotOrganizations } from 'reduxStore/organization/organizationSelectors';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

import { setCreatingOrganization } from 'reduxStore/createProfile/createProfileSlice';
import { FullScreenLoader } from 'ui';
import { ILogInBody } from 'types';

const AuthHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authCode = useRef(location.state?.code);
  const authProvider = useRef(location.state?.provider);
  const hasInitiatedRequest = useRef(false);

  const [isReady, setIsReady] = useState(false);

  const isAuth = useAppSelector(getIsAuth);
  const userHasOrganizations = useAppSelector(userGotOrganizations);

  useEffect(() => {
    const processAuthentication = async () => {
      const code = authCode.current;
      const provider = authProvider.current;
      if (!code) {
        navigate('/', { replace: true });
        return;
      }

      const body: ILogInBody = {
        code: code,
        provider: provider,
        redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      };

      try {
        await dispatch(signIn(body)).unwrap();
        await dispatch(retrieveAccountInfo(null)).unwrap();
        setIsReady(true);
      } catch (error) {
        console.error('Authentication or organizations fetch failed:', error);
        navigate(ROUTES.SIGNIN, { replace: true });
      } finally {
        clear0AuthState();
      }
    };

    if (!hasInitiatedRequest.current) {
      hasInitiatedRequest.current = true;
      processAuthentication();
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!isReady) return;

    if (isAuth) {
      if (!userHasOrganizations) {
        dispatch(setCreatingOrganization(true));
      }
      const destination = userHasOrganizations
        ? ROUTES.DASHBOARD
        : ROUTES.ORGANIZATION_PROFILE;
      navigate(destination, { replace: true });
    }
  }, [isReady, isAuth, userHasOrganizations, navigate, dispatch]);

  return <FullScreenLoader />;
};

export default AuthHandler;
